import Vue from 'vue'

import UTILS from './Utils'
import GLOBAL from './Global'

let instance = {
    init() {
        Vue.mixin({
            data() {
                return {
                    UTILS,
                    GLOBAL
                }
            }
        })
    }
}

export default instance