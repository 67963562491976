import Vue from 'vue'
import App from './App.vue'

import Instance from './global/Instance'
Instance.init()

Vue.config.productionTip = false;

import Flicking from "@egjs/vue-flicking";
import "@egjs/vue-flicking/dist/flicking.css";
import "@egjs/vue-flicking/dist/flicking-inline.css";

Vue.use(Flicking);

new Vue({
    render: h => h(App),
}).$mount('#app')
