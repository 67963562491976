<template>
  <div id="app">
    <Notify />
  </div>
</template>

<script>
import Notify from "./components/Notify.vue"

export default {
  components: {
      Notify
  }
}
</script>

<style>
  #app {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
</style>
