
export default {
    display(type, id = 'app', animation = false) {
        if (type == 'close') {
            let options = {
                method: 'POST',
                body: JSON.stringify({})
            }
            document.querySelector(`#${id}`).classList.add('hide')
            setTimeout(() => {
                document.querySelector(`#${id}`).style.display = 'none'
                fetch('http://notify/closeSystem', options)
            }, 490)
            
        } else if (type == 'closeAll') {
            let containers = document.querySelectorAll('.page')
  
            for (let i in containers) {
                if (typeof containers[i] == 'object') {
                    if (animation) {
                        containers[i].classList.add('hide')
                        setTimeout(() => {containers[i].style.display = 'none'}, 490)
                    } else {
                        containers[i].style.display = 'none'
                    }
                } 
            }
        } else if (type == 'open') {
            document.querySelector(`#${id}`).classList.remove('hide')
            document.querySelector(`#${id}`).classList.add('fade')
            document.querySelector(`#${id}`).style.display = 'flex'
        } 
    },
    removeNotify(number) {
        console.log(`notify-content ${number} disappear`)
        // document.querySelector(`.notify-content ${number} disappear`).remove()
        const teste = document.querySelector(`notify-content ${number} disappear`)
        teste.parentElement.remove()
        
    }
}